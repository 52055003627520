import { merge } from 'utilities/obj.js';
import { interFontFamily } from 'utilities/interFontFamily.js';
import { unescapeHtml } from 'utilities/core.js';
import {
  elemBind,
  elemAnimate,
  elemAppend,
  elemFromObject,
  elemRemove,
  elemStyle,
} from 'utilities/elem.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { defineControl } from '../../../../../shared/control_definitions.js';
import { destroyControl } from '../../../../../shared/control-lifecycle.js';
import Control from '../../shared/controls/Control.js';

const BASE_FONT_SIZE = 16;
class AnnotationOverlayControl extends Control {
  constructor(video) {
    super(video);
    this.annotations = {};
    this.unbinds = [];
    this.video = video;
    this._conversionOpportunityType = 'link';

    // when the clickForSoundButton is visible, we want to make sure all annotations on the
    // right are pushed down... they can go back up when the clickForSoundButton is gone
    this.unbinds.push(
      this.video.bind('clickforsoundvisibilitychange', (isVisible) => {
        this.adjustSpacingForClickForSound(isVisible);
      }),
    );
  }

  mount(rootElem) {
    const styles = {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      pointerEvents: 'none',
      maxWidth: '50%',
      width: '100%',
    };

    // set up two child nodes, one for left annotations and one for right
    const containerElem = elemFromObject({
      childNodes: [
        {
          style: merge({
            ...styles,
            alignItems: 'flex-start',
          }),
        },
        {
          style: merge({
            ...styles,
            right: '0px',
            alignItems: 'flex-end',
          }),
        },
      ],
    });

    elemAppend(rootElem, containerElem);
    this.rootElem = rootElem.childNodes[0];
  }

  destroy() {
    for (const annotation in this.annotations) {
      if (this.annotations[annotation].config.destroy) {
        this.annotations[annotation].config.destroy();
      }
    }

    destroyControl(this);
  }

  onControlPropsUpdated(prevProps) {
    const fontSize = `${this.roundBaseFontSize()}px`;
    const borderRadius = `${Math.round(this.roundBaseFontSize() / 3)}px`;

    // go through all annotations and update their styles
    for (const annotation in this.annotations) {
      elemStyle(this.annotations[annotation].config.rootElem, { fontSize, borderRadius });
      if (this.annotations[annotation].config.onControlPropsUpdated) {
        this.annotations[annotation].config.onControlPropsUpdated(prevProps);
      }
    }
  }

  mountConfig(config, opts) {
    return dynamicImport('assets/external/interFontFace.js').then(() => {
      return this.mounted.then(() => {
        const annotation = { config };

        // if you're trying to mount an annotation with a name that is already here
        // that means it's already being displayed, and we wont show it again
        if (this.annotations[opts.name]) {
          return Promise.resolve();
        }

        this.annotations[opts.name] = { config };

        // fire an event any time we got from showing no annotations to showing at least one annotation
        if (Object.keys(this.annotations).length === 1) {
          this.video.trigger('annotationvisible');
        }
        this.video.trigger('annotation-impression', {
          annotationId: annotation.config.annotationId,
        });

        annotation.config.props = this.props;

        const positionOrLocation = annotation.config.location || annotation.config.position;

        const leftOrRightRootElem = positionOrLocation === 'left' ? 0 : 1;
        const backgroundAndFontColors = getThemeColors(config);
        const url = config.url ? sanitizeUrl(config.url) : '';
        const spacing = this.roundBaseFontSize() * 0.75;
        const style = merge(
          {
            fontFamily: interFontFamily,
            margin: `${spacing}px ${spacing}px 0 ${spacing}px`,
            pointerEvents: 'auto',
            display: 'block',
            lineHeight: '1.2em',
            fontWeight: '600',
            fontSize: `${this.roundBaseFontSize()}px`,
            letterSpacing: '0.03em',
            padding: `${spacing}px ${spacing + 3}px`,
            borderRadius: `${this.roundBaseFontSize() / 5}px`,
            boxShadow: ' 0 0 5px 0 rgba(108, 108, 108, 0.5)',
            ...backgroundAndFontColors,
          },
          annotation.config.style,
        );

        annotation.rootElem = elemFromObject({
          tagName: 'a',
          target: '_blank',
          innerText: unescapeHtml(sanitizeText(config.text)),
          href: url,
          class: 'w-css-reset',
          style,
        });

        this.addClickBinding(annotation);
        elemAppend(this.rootElem.childNodes[leftOrRightRootElem], annotation.rootElem);
        const mountResult = annotation.config.mount(annotation.rootElem);
        const mountPromise =
          mountResult && mountResult.then && mountResult.catch ? mountResult : Promise.resolve();

        return mountPromise.then(() => {
          // Until the promise has resolved, we may be clipping the rootElem to
          // obscure any initialization jank. But we want it visible when we
          // start to animate in.
          elemStyle(annotation.rootElem, { clip: '' });

          const transition = config.transition || 'pop';
          const transitionTime = config.transitionTime || 200;

          if (transition === 'pop') {
            this.animateIn(annotation.rootElem, transition, transitionTime);
          } else {
            elemStyle(config.rootElem, { transform: 'scale3d(1,1,1)', opacity: 1 });
          }
        });
      });
    });
  }

  // where we log conversion events and preventDefault if there is no url
  addClickBinding(annotation) {
    this.unbinds.push(
      elemBind(annotation.rootElem, 'click', (e) => {
        const clickData = { annotationId: annotation.config.annotationId };
        // if there is no url, don't bother trying to open in a new tab
        if (!annotation.config.url) {
          // undocumented option for an onClick handler
          // used internally for midrollLinks to open other overlays
          if (annotation.config.onClick) {
            this.video.trigger('annotation-click', clickData);
            annotation.config.onClick();
          }

          e.preventDefault();
        }

        const conversionData = {
          converted: true,
          link: annotation.config.url,
          co_key: annotation.config.conversionOpportunityKey || null,
          co_type: this._conversionOpportunityType,
          time: this.video.time(),
        };

        // only pause the video if if there is a url to go to
        if (annotation.config.url) {
          this.video.trigger('annotation-click', clickData);
          this.video.trigger('conversion-link', conversionData);

          this.video.pause();
        }
      }),
    );
  }

  hide(opts) {
    const annotation = this.annotations[opts.name];

    if (!annotation) {
      return;
    }

    delete this.annotations[opts.name];

    // trigger an event when no annotations are visible any longer
    if (Object.keys(this.annotations).length === 0) {
      this.video.trigger('noannotationsvisible');
    }

    const rootElem = annotation.config.rootElem;
    const transition = annotation.config.transition || 'pop';
    const transitionTime = annotation.config.transitionTime || 200;

    if (rootElem === document.activeElement) {
      this.video._focusNextVisibleElem();
    }

    if (transition === 'pop') {
      this.animateOut(rootElem, transition, transitionTime).then(() => {
        elemRemove(rootElem);
      });
    } else {
      elemRemove(rootElem);
    }
  }

  animateIn(rootElem, transition, transitionTime) {
    return new Promise((resolve) => {
      elemStyle(rootElem, { transform: 'scale3d(.7,.7,1)', opacity: 0 });
      setTimeout(() => {
        elemAnimate(
          rootElem,
          { transform: 'scale3d(1,1,1)', opacity: 1 },
          { time: transitionTime, easing: 'ease-in', callback: resolve },
        );
      }, 0);
    });
  }

  animateOut(rootElem, transition, transitionTime) {
    return new Promise((resolve) => {
      elemStyle(rootElem, { transform: 'scale3d(1,1,1)', opacity: 1 });
      setTimeout(() => {
        elemAnimate(
          rootElem,
          { transform: 'scale3d(.7,.7,1)', opacity: 0 },
          { time: transitionTime, easing: 'ease-out', callback: resolve },
        );
      }, 0);
    });
  }

  roundBaseFontSize() {
    return Math.round(this.props.scale * BASE_FONT_SIZE);
  }

  updateFromOptions(options) {
    if (!options.name) {
      return;
    }

    const overlay = this.video._impl._overlays[options.name];
    // if there is no defined overlay, there is nothing to update so get outta here
    if (!overlay) {
      return;
    }

    for (const opt in options) {
      this.updateFromOption(opt, options);
    }
  }

  updateFromOption(optionName, options) {
    switch (optionName) {
      case 'text':
        this.updateAnnotationText(options);
        break;
      case 'url':
        this.updateAnnotationUrl(options);
        break;
      case 'time':
        this.updateAnnotationTime(options);
        break;
      case 'duration':
        this.updateAnnotationDuration(options);
        break;
      default:
        break;
    }
  }

  updateAnnotationText(options) {
    const safeText = sanitizeText(options.text || '');
    this.video._impl._overlays[options.name].text = unescapeHtml(safeText);

    if (this.annotations[options.name]) {
      this.annotations[options.name].config.rootElem.innerText = safeText;
    }
  }

  updateAnnotationUrl(options) {
    const safeUrl = sanitizeUrl(options.url);

    this.video._impl._overlays[options.name].url = safeUrl;

    if (this.annotations[options.name]) {
      this.annotations[options.name].config.rootElem.href = safeUrl;
    }
  }

  updateAnnotationTime(options) {
    this.video._impl._overlays[options.name].time = options.time;

    if (this.annotations[options.name]) {
      this.annotations[options.name].config.time = options.time;
    }
  }

  updateAnnotationDuration(options) {
    this.video._impl._overlays[options.name].duration = options.duration;

    if (this.annotations[options.name]) {
      this.annotations[options.name].config.duration = options.duration;
    }
  }

  adjustSpacingForClickForSound(isClickForSoundVisible) {
    if (this.rootElem && this.rootElem.childNodes.length >= 2) {
      if (isClickForSoundVisible) {
        elemStyle(this.rootElem.childNodes[1], { marginTop: `${this.props.scale * 56}px` });
      } else {
        elemStyle(this.rootElem.childNodes[1], { marginTop: '0px' });
      }
    }
  }
}

const getThemeColors = (config) => {
  if (config.theme && config.theme === 'light') {
    return {
      color: '#505050',
      backgroundColor: 'rgba(255,255,255,.80)',
    };
  }

  return {
    color: '#fff',
    backgroundColor: 'rgba(0,0,0,.55)',
  };
};

const sanitizeUrl = (unsanitizedUrl) => {
  const url = unescapeHtml(unsanitizedUrl);
  if (
    url.substring(0, 4) == 'http' ||
    url.substring(0, 6) == 'mailto' ||
    url.substring(0, 3) == 'ftp' ||
    url.substring(0, 1) == '/' ||
    url.substring(0, 1) == '#' ||
    url.substring(0, 3) == 'tel'
  ) {
    return url;
  }
  return `http://${url}`;
};

const sanitizeText = (unsanitizedText) => {
  return unsanitizedText
    .replace(/<br\/>/g, '\n')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\n/g, '<br/>');
};
AnnotationOverlayControl.handle = 'annotationOverlay';
AnnotationOverlayControl.type = 'above-control-bar';
AnnotationOverlayControl.sortValue = 500;

defineControl(AnnotationOverlayControl);

export default AnnotationOverlayControl;
