import '../video/controls/ModalOverlayControl.js';
import '../video/controls/AnnotationOverlayControl.js';
import { wlog } from 'utilities/wlog.js';
import { bindify } from 'utilities/bindify.js';
import { PlayerBehavior } from './PlayerBehavior.js';
import { maybeLoadAndSetupEmbedLinksThrottled } from '../../../modules/_embed_links.js';

export class OverlaysBehavior extends PlayerBehavior {
  init() {
    this.overlaysQueue = [];
    this.unbinds = [];

    // when popovers hide overlays behavior is getting torn down
    // we need to handle any open overlays
    this.unbinds.push(
      this.impl.on('beforepopoverhide', () => {
        if (this._activeOverlayName && this._activeOverlayConfig.type === 'modal') {
          this.impl.publicApi.unsuspend({ state: 'paused' });
          if (this._activeOverlayConfig.options) {
            const activeOverlayTime = this._activeOverlayConfig.options.time;
            this.cancelOverlay(this._activeOverlayName).then(() => {
              // if it's a before turnstile we need to re-suspend to suppress play
              if (activeOverlayTime === 'before') {
                this.impl.publicApi.suspend();
              }
            });
          }
        }
      }),
    );
  }

  requestOverlay(name) {
    // when requesting an ovelray that is already active
    // we can just return a fulfilled Promise
    if (this._activeOverlayName === name) {
      return Promise.resolve();
    }

    const config = this.impl._overlays[name];
    if (!config) {
      wlog.error(`No overlay with name ${name} is defined`);
    } else {
      switch (config.type) {
        case 'modal':
          return this.showOrQueueOverlay(name, config);
        default:
          return this.showOrQueueOverlay(name, this.getConfig(config));
      }
    }
  }

  showOrQueueOverlay(name, config) {
    // if the activeOverlay is of type modal, setup show binding and start a queue
    // of overlays that will need to be displayed
    if (this._activeOverlayConfig && this._activeOverlayConfig.type === 'modal') {
      return new Promise((resolve) => {
        this.bind('show', (n) => {
          if (n === name) {
            resolve();
            return this.unbind;
          }
        });

        this.overlaysQueue.push([name, config]);
      });
    }
    return this.showOverlay(name, config);
  }

  cancelOverlay(name) {
    const config = this.impl._overlays[name];
    // if there is no config, they're trying to cancel a non-existant overlay
    if (!config) {
      return Promise.resolve();
    }
    // remove it from the queue if it's there.
    this.overlaysQueue = this.overlaysQueue.filter((e) => e[0] !== name);

    // you can't cancel "modalOverlay B" if "modalOverlay A" is active and visible
    if (this._activeOverlayConfig) {
      if (this._activeOverlayName !== name && this._activeOverlayConfig.type === 'modal') {
        return Promise.resolve();
      }
    }

    return this.getControlFromConfigType(config.type).then((control) => {
      this._activeOverlayName = null;
      this._activeOverlayConfig = null;

      // if there is a queue, go the next item in queue, else just hide and unsuspend
      if (this.overlaysQueue.length > 0) {
        const [nextName, nextConfig] = this.overlaysQueue.pop();
        this.showOverlay(nextName, nextConfig).then(() => {
          // we want to move onto the next item in the queue, but that next item might not
          // be of the same type as the one that just finished - so we might need to hide
          // the control that is just finishing
          if (nextConfig.type !== config.type) {
            this.hideControl(config, control, name);
          }
          this.trigger('hide', name);
        });
      } else {
        this.hideControl(config, control, name);
        this.trigger('hide', name);
        this.impl.publicApi.unsuspend();
      }
    });
  }

  hideControl(config, control, name) {
    // only annotations need the name of the overlay that should be hidden
    if (config.type === 'annotation') {
      control.hide({ name });
    } else {
      control.hide();
    }
  }

  showOverlay(name, config) {
    this._activeOverlayName = name;
    this._activeOverlayConfig = config;

    // make suspend async - important for overlays at the end of the video
    // we want to make sure we suspend before the 'afterend' event is fired
    if (config.type === 'modal') {
      this.impl.publicApi.suspend();
    }

    return this.getControlFromConfigType(config.type)
      .then((control) => {
        const options = config.type === 'annotation' ? { name } : {};
        const mountResult = control.mountConfig(config, options);
        const mountPromise =
          mountResult && mountResult.then && mountResult.catch ? mountResult : Promise.resolve();

        // modals stop the video, annotations do not :)
        return mountPromise.then(() => {
          // ensure that we checking for embed links on mount
          maybeLoadAndSetupEmbedLinksThrottled(this.rootElem);
          this.trigger('show', name);
        });
      })
      .catch(() => {
        // since we're suspending outside of this promise, if it were to break, we should make sure to unsuspend
        if (config.type === 'modal') {
          this.impl.publicApi.unsuspend();
        }
      });
  }

  // if there is already a mount function on the config
  // we dont need to define one and should use the provided one
  getConfig(config) {
    if (config.mount) {
      return config;
    }

    return {
      ...config,
      mount(rootElem) {
        this.rootElem = rootElem;
      },
    };
  }

  getControlFromConfigType(type) {
    if (type === 'annotation') {
      return this.impl.whenControlMounted('annotationOverlay');
    }
    if (type === 'transcript') {
      return this.impl.whenControlMounted('transcript');
    }

    return this.impl.whenControlMounted('modalOverlay');
  }

  undefineOverlay(name) {
    if (this.impl._overlays[name]) {
      delete this.impl._overlays[name];
    }
  }
}

bindify(OverlaysBehavior.prototype);
OverlaysBehavior.handle = 'overlays';
