import { merge } from 'utilities/obj.js';
import { elemAnimate, elemAppend, elemFromObject, elemRemove, elemStyle } from 'utilities/elem.js';
import Control from '../../shared/controls/Control.js';
import { destroyControl } from '../../../../../shared/control-lifecycle.js';
import { defineControl } from '../../../../../shared/control_definitions.js';

class ModalOverlayControl extends Control {
  constructor(video) {
    super(video);
    this.queue = [];
    this.video = video;
  }

  destroy() {
    if (this._active && this._active.config.destroy) {
      this._active.config.destroy();
    }

    destroyControl(this);
  }

  mount(rootElem) {
    this.rootElem = rootElem;
  }

  onControlPropsUpdated(prevProps) {
    if (this._active) {
      this._active.config.props = this.props;
      if (this._active.config.onControlPropsUpdated) {
        this._active.config.onControlPropsUpdated(prevProps);
      }
    }
  }

  mountConfig(config) {
    return this.mounted.then(() => {
      // save the old overlay so we can animate it out
      this._prev = this._active;

      this._active = { config };
      this._active.config.props = this.props;
      const style = merge(
        {
          backgroundColor: 'rgba(0,0,0,.55)',
          backgroundImage:
            'radial-gradient(farthest-corner, rgba(0,0,0,.05), rgba(0,0,0,.2), rgba(0,0,0,0.9))',
          clip: 'rect(0,0,0,0)',
          color: '#fff',
          fontFamily: 'Helvetica, Sans-Serif',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
        },
        this._active.config.style,
      );
      this._active.rootElem = elemFromObject({ style, class: 'w-css-reset' });
      elemAppend(this.rootElem, this._active.rootElem);
      const mountResult = this._active.config.mount(this._active.rootElem);
      const mountPromise =
        mountResult && mountResult.then && mountResult.catch ? mountResult : Promise.resolve();

      return mountPromise.then(() => {
        // Until the promise has resolved, we may be clipping the rootElem to
        // obscure any initialization jank. But we want it visible when we
        // start to animate in.
        elemStyle(this._active.rootElem, { clip: '' });

        const { transition } = this.getTransitionOptions(config);

        let prevPromise;
        if (this._prev) {
          prevPromise = this.animateOut(this._prev.rootElem, config);
        } else {
          prevPromise = Promise.resolve();
        }

        // When the animations are complete, remove the ability to focus
        // elements "behind" the modal
        if (transition === 'fade') {
          prevPromise.then(() => {
            this.animateIn(this._active.rootElem, config).then(() => {
              this.removeAbilityToFocusControls();
            });
          });
        } else if (transition === 'slide') {
          this.animateIn(this._active.rootElem, config).then(() => {
            this.removeAbilityToFocusControls();
          });
        }
      });
    });
  }

  hide() {
    const active = this._active;
    if (!active) {
      return;
    }
    const rootElem = active.rootElem;
    this._active = null;

    this.animateOut(rootElem, active.config).then(() => {
      // make sure the tabindex is returned to other elements
      this.restoreAbilityToFocusControls();
      elemRemove(rootElem);
    });
  }

  animateIn(rootElem, config) {
    const { transition, transitionTime, transitionDirection } = this.getTransitionOptions(config);
    return new Promise((resolve) => {
      if (transition === 'fade') {
        elemStyle(rootElem, { opacity: 0 });
        setTimeout(() => {
          elemAnimate(rootElem, { opacity: 1 }, { time: transitionTime, callback: resolve });
        }, 0);
      } else if (transition === 'slide') {
        let startTransform = 'translateX(100%)';

        if (transitionDirection === 'ltor') {
          startTransform = 'translateX(-100%)';
        }

        elemStyle(rootElem, { transform: startTransform });
        setTimeout(() => {
          elemAnimate(
            rootElem,
            { transform: 'translateX(0)' },
            { time: transitionTime, easing: 'linear', callback: resolve },
          );
        }, 0);
      }
    });
  }

  animateOut(rootElem, config) {
    const { transition, transitionTime } = this.getTransitionOptions(config);
    return new Promise((resolve) => {
      if (transition === 'fade') {
        elemStyle(rootElem, { opacity: 1 });
        setTimeout(() => {
          elemAnimate(rootElem, { opacity: 0 }, { time: transitionTime, callback: resolve });
        }, 0);
      } else if (transition === 'slide') {
        elemStyle(rootElem, { transform: 'translateX(0)' });
        setTimeout(() => {
          elemAnimate(
            rootElem,
            { transform: 'translateX(100%)' },
            { time: transitionTime, easing: 'linear', callback: resolve },
          );
        }, 0);
      }
    });
  }

  destroyActiveConfig() {
    return new Promise((resolve) => {
      if (this._active && this._active.config) {
        const destroyForReal = () => {
          if (this._active.config.destroy) {
            this._active.config.destroy();
          }
          elemRemove(this._active.rootElem);
          resolve();
        };

        this.animateOut(this._active.rootElem).then(destroyForReal);
      } else {
        resolve();
      }
    });
  }

  getTransitionOptions(config) {
    return {
      transition: config.transition || 'fade',
      transitionTime: config.transitionTime || 400,
      transitionDirection: config.transitionDirection || '',
    };
  }

  // for future us: move these focus functions into their own async require
  // that extends the impl so its not coupled the ui behavior implementation
  removeAbilityToFocusControls() {
    this._changedFocus = {
      elems: [],
      previousTabIndex: [],
    };

    const mountRefs = this.video.behaviors.ui.mountRefs;

    // get all the elems we will want to keep track of
    Object.keys(this.video.controls).forEach((controlHandle) => {
      if (controlHandle !== ModalOverlayControl.handle) {
        const nodeList = mountRefs[controlHandle].querySelectorAll(
          'button, [href], input, select, textarea, [tabindex="0"]',
        );
        this._changedFocus.elems = this._changedFocus.elems.concat(
          Array.prototype.slice.call(nodeList),
        );
      }
    });

    // go through the elems, save their previous state and set the next val
    this._changedFocus.elems.forEach((elem) => {
      this._changedFocus.previousTabIndex.push(elem.getAttribute('tabindex'));
      elem.setAttribute('tabindex', -1);
    });
  }

  restoreAbilityToFocusControls() {
    this._changedFocus.elems.forEach((elem, index) => {
      const prevValue = this._changedFocus.previousTabIndex[index];
      if (prevValue != null) {
        elem.setAttribute('tabindex', prevValue);
      } else {
        elem.removeAttribute('tabindex');
      }
    });

    // reset
    this._changedFocus = {};
  }
}

ModalOverlayControl.handle = 'modalOverlay';
ModalOverlayControl.type = 'foreground';
ModalOverlayControl.sortValue = 500;

defineControl(ModalOverlayControl);

export default ModalOverlayControl;
